<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar">
    
    <div class="container">       
        <img src="{{ company.icon.path }}" alt="Logo de ARCOL" class="img-fluid">        
    </div>
    <!--<div class="collapse navbar-collapse" id="navbarCollapse">        
        <div>
            <ul class="text-right list-unstyled list-inline mb-0 nav-social">
                <li class="list-inline-item text-white nav-number"><span>{{ phone }}</span></li>
                <li class="list-inline-item"><a href="{{ linkedin }}"><i class="ti-linkedin"></i></a></li>
                <li class="list-inline-item text-white nav-number"><a href="https://wa.me/50683372092" target="_blank"><i class="ti-mobile"></i></a></li>
            </ul>
        </div>
    </div>-->
</nav>

<app-customer-testimonials></app-customer-testimonials>
   

