import { Component, OnInit } from '@angular/core';
import { GaleryService } from '../../shared/galery/services/galery.service'
import { CookieService } from 'ngx-cookie-service';
import { HomeService } from './services/home.service';
import { HelperService } from 'src/app/shared/helper/services/helper-service.service';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {  
  private companyInfo: any = [];
  private dataGalery: any = [];
  company: any = {};
  galery: any = []; 
  contact: any = [];
  phone: string = '';
  linkedin: string = '';
  facebook: string = '';
  instagram: string = '';
  email: string = '';
  

  constructor(private galeryService : GaleryService,
             private cookieService: CookieService,
             private homeService: HomeService,
             private helperService: HelperService)
  {}

  currentSection = 'home';

  ngOnInit(): void {  

    this.getInfoCompany(); 
    this.helperService.getInfoContact();  
    this.getImages();      

    if(this.cookieService.check('phone'))
    {
      this.phone = this.cookieService.get('phone');
      this.linkedin = this.cookieService.get('linkedin');
      this.instagram = this.cookieService.get('instagram');
      this.facebook = this.cookieService.get('facebook');
      this.email = this.cookieService.get('email');
    }
    let i = 0;
    setInterval(() => {  
      (<HTMLImageElement>document.querySelector("#isSlideImage")).src = this.galery[i].path;  
      i++;
      if (i === this.galery.length) 
      { 
        i = 0; 
      }  
    }, 2500);
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
      navbar.style.backgroundColor = '#1a1a1a';
      navbar.style.padding = '15px 0px';
    }
    else {
      navbar.style.backgroundColor = '';
      navbar.style.padding = '20px';
    }
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  getImages()
  {
    if(!this.cookieService.check('images-home')){
      this.galeryService.getGalery$({}).subscribe((data) => {
        this.dataGalery = data.filter((item: { section: { idSection: number}; }) => 
        item.section.idSection === 1);
        this.galery = this.dataGalery;
        this.cookieService.set('images-home',JSON.stringify(this.galery),1,'/')
      });
    }else
    {
      const data = this.cookieService.get('images-home');
      if (data) {
        this.galery = JSON.parse(data);
      } else {
        console.log("No se encontró ninguna cookie con el nombre galery.");
      }      
    }  
    
  }

  getInfoCompany()
  {
    if(!this.cookieService.check('company')){
      this.homeService.getCompany$({}).subscribe((dataCompany) => {
        this.companyInfo = dataCompany;
        this.company = this.companyInfo[0];   
      });
    }else
    {
      const data = this.cookieService.get('company');
      if (data) {
        this.companyInfo = JSON.parse(data);  
        this.company = this.companyInfo[0];      
      } else {
        console.log("No se encontró ninguna cookie con el nombre company.");
      }      
    }  
  } 

}
