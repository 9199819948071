import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ServicesComponent } from './services/services.component';
import { ClientComponent } from './client/client.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { TypewriterDirective } from './typewriter.directive';
import { GaleryComponent } from './galery/galery.component';
import { CertificationComponent } from './certification/certification.component';
import { CustomerTestimonialsComponent } from './customer-testimonials/customer-testimonials/customer-testimonials.component';
import { PublishTestimonialsComponent } from './publish-testimonials/publish-testimonials.component';
import { ReadMoreComponent } from './read-more/read-more.component';

@NgModule({
  declarations: [ServicesComponent, ClientComponent, ContactComponent, FooterComponent, ScrollspyDirective, TypewriterDirective,GaleryComponent,CertificationComponent,CustomerTestimonialsComponent,PublishTestimonialsComponent, ReadMoreComponent],
  imports: [
    CommonModule,
    CarouselModule,
    ScrollToModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [ServicesComponent,ClientComponent, ContactComponent, FooterComponent, ScrollspyDirective, TypewriterDirective,GaleryComponent,CertificationComponent,CustomerTestimonialsComponent,PublishTestimonialsComponent]
})

export class SharedModule { }
