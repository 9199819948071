<!-- STRAT TESTIMONIAL -->
<section class="section bg-client" id="testi">
    <div class="container">
        <div class="row justify-content-center mt-5 mb-5">
            <div class="col-lg-12">
                <owl-carousel-o [options]="customOptions">
                    @for (review of reviews; track $index) {
                        <ng-template carouselSlide>
                            <div class="text-center w-75 mx-auto">
                                <div class="testi-icon text-white">
                                    <i class="ti-quote-left"></i>
                                </div>
                                <div class="mt-3">
                                    <p class="user-review text-center">{{ review.comment }}</p>                                         
                                    <div class="testi-icon text-white">
                                        <i class="ti-quote-right"></i>
                                    </div>   
                                    <p class="testi-user-name text-center text-white mb-0 mt-4">
                                        Servicio recibido: {{ review.serviceType.name }}</p>      
                                    <p class="testi-user-name text-center text-white mb-0 mt-4">
                                        {{ review.nameCustomer }}, {{ review.companyCustomer }}</p>                                                                   
                                    <p class="text-muted">
                                        @for (star of createStars(5); track $index) {
                                            <div *ngIf="star <= review.punctuation; then thenBlock else elseBlock"></div>
                                                <ng-template #thenBlock><span class="ti-star text-warning"></span></ng-template>
                                                <ng-template #elseBlock><span class="ti-star"></span></ng-template>
                                        }
                                    </p>
                                </div>
                            </div>
                        </ng-template>
                    }    
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- END TESTIMONIAL -->