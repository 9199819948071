import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HomeService } from '../index/services/home.service';
import { HelperService } from 'src/app/shared/helper/services/helper-service.service';


@Component({
  selector: 'app-approve-testimonials',
  templateUrl: './approve-testimonials.component.html',
  styleUrl: './approve-testimonials.component.scss'
})
export class ApproveTestimonialsComponent {
  private companyInfo: any = [];
  company: any = {};
  contact: any = [];
  phone: string = '';
  linkedin: string = '';
  facebook: string = '';
  instagram: string = '';
  email: string = '';
  

  constructor(private cookieService: CookieService,
             private homeService: HomeService,
             private helperService: HelperService)
  {}

  currentSection = 'home';

  ngOnInit(): void {  

    const navbar = document.getElementById('navbar');
    navbar.style.backgroundColor = '#1a1a1a';
    navbar.style.padding = '15px 0px';

    this.getInfoCompany(); 
    this.helperService.getInfoContact();      

    if(this.cookieService.check('phone'))
    {
      this.phone = this.cookieService.get('phone');
      this.linkedin = this.cookieService.get('linkedin');
      this.instagram = this.cookieService.get('instagram');
      this.facebook = this.cookieService.get('facebook');
      this.email = this.cookieService.get('email');
    }
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
    const navbar = document.getElementById('navbar');
    navbar.style.backgroundColor = '#1a1a1a';
    navbar.style.padding = '15px 0px';
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  
  getInfoCompany()
  {
    if(!this.cookieService.check('company')){
      this.homeService.getCompany$({}).subscribe((dataCompany) => {
        this.companyInfo = dataCompany;
        this.company = this.companyInfo[0];   
      });
    }else
    {
      const data = this.cookieService.get('company');
      if (data) {
        this.companyInfo = JSON.parse(data);  
        this.company = this.companyInfo[0];      
      } else {
        console.log("No se encontró ninguna cookie con el nombre company.");
      }      
    }  
  } 
}
