import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { IndexComponent } from './index/index.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { ApproveTestimonialsComponent } from './approve-testimonials/approve-testimonials.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    },
    {
        path: '',
        component: IndexComponent
    },
    {
        path: 'testimonials/:review',
        component: TestimonialsComponent
    },
    {
        path: 'approve/testimonials/:review',
        component: ApproveTestimonialsComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
