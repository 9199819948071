<!-- CONTACT FORM START-->
<section class="section " id="contact">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-xl-6 col-10">
                <div class="text-center">
                    <i class="ti-pencil-alt title-icon text-muted"></i>
                    <h3 class="title"><span class="fw-bold">Formulario publicación de testimonios</span></h3>
                </div>
            </div>
        </div>
        <div class="row">            
            <div class="col-lg-12">
                <div class="custom-form mt-4 pt-4">
                    <form method="post" (ngSubmit)="sendMsg()" [formGroup]="itemData">
                        <p id="error-msg"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-lg-6">
                                <label for="nameCustomer" class="form-label">Nombre:</label>
                                <div class="form-group mt-2">
                                    <input name="name" id="name" type="text" class="form-control" placeholder="Nombre:*" formControlName="nameCustomer" required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="companyCustomer" class="form-label">Empresa:</label>
                                <div class="form-group mt-2">
                                    <input name="company" id="company" type="text" class="form-control" placeholder="Empresa:*" formControlName="companyCustomer" required>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <label for="serviceType" class="form-label">Servicio:</label>
                                <select cSelect formControlName="serviceType" id="lblService" required class="form-control">
                                    <option [value]="null">Selecciona una opción</option>
                                    <option *ngFor="let option of services " [value]="option.idServiceType">{{
                                        option.name }}</option>
                                </select>
                            </div>
                            <div class="col-lg-6">
                                <label for="canPublish" class="form-label">Este testimonio puede ser publicado:</label>
                                <select cSelect formControlName="canPublish" id="lblPublish" required class="form-control">
                                    <option [value]="null">Selecciona una opción</option>
                                    <option *ngFor="let option of optionsPublish " [value]="option.value">{{
                                        option.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">                               
                                <div class="form-group mt-2">   
                                    <label for="puntuaction" class="form-label">Puntuación:&nbsp;&nbsp;&nbsp;</label>                                 
                                    <!--input type="text" class="form-control" id="punctuation" placeholder="Puntuacion" formControlName="punctuation"  required /-->
                                    <span class="star" *ngFor="let star of stars"  [ngClass]="{ 'filled': star <= selectedStars }">&#9733;</span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="isPublish" class="form-label">Publicar testimonio:</label>
                                <select cSelect formControlName="isPublish" id="lblPublish" required class="form-control">
                                    <option [value]="null">Selecciona una opción</option>
                                    <option *ngFor="let option of optionsPublish " [value]="option.value">{{
                                        option.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mt-2">
                                    <textarea name="comments" id="comments" rows="10" class="form-control" 
                                    placeholder="Si te parece, contestar en narrativa (sin poner las preguntas) podría ser una guía para el comentario: 
                                                ¿Cómo era la situación antes de contactarnos? 
                                                ¿Qué beneficios percibe?
                                                ¿Qué le dirías a otra persona que no nos conoce?
                                                ¿Nos recomiendas?" formControlName="comment" required></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="flag">
                            <div class="col-lg-12 text-end">
                                <input type="submit" id="submit" name="send" class="submitBnt btn btn-primary" value="Publicar">
                                <div id="simple-msg"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CONTACT FORM END-->