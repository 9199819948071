import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ClientService } from './services/client.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})

/**
 * Client component
 */
export class ClientComponent implements OnInit {

  private reviewsData : any = [];
  reviews : any = [];
  stars : any = [];

  ngOnInit(): void {
    this.getReviews();
  }

  constructor(private clientService : ClientService,
      private cookieService : CookieService) { }

  getReviews() {
    //if(!this.cookieService.check('reviews') || this.cookieService.get('reviews') === "null"){
      this.clientService.getReviews$({canPublish: true, isPublish: true}).subscribe((reviewsData) => {
        this.reviewsData = reviewsData;
        this.reviews = this.reviewsData;
        //this.cookieService.set('reviews',JSON.stringify(this.reviews),1,'/');
      });
    /*}else
    {
      const data = this.cookieService.get('reviews');
      if (data) {
        this.reviewsData = JSON.parse(data);
        this.reviews = this.reviewsData;
      } else {
        console.log("No se encontró ninguna cookie con el nombre servicesType.");
      }      
    }*/
  }

  createStars(number){
    this.stars = [];
    for(var i = 1; i <= number; i++){
      this.stars.push(i);
    }
    return this.stars;
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
    },
    nav: true,
    navText: [ '<div class="test_nav_right"><i class="ti-angle-right"></i></div>', '<div class="test_nav_left"><i class="ti-angle-left"></i></div>' ]
  }
}
