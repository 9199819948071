import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgParticlesModule } from "ng-particles";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PagesRoutingModule } from './pages-routing.module'
import { SharedModule } from '../shared/shared.module';
import { IndexComponent } from './index/index.component';
import { HttpClientModule } from '@angular/common/http';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { ApproveTestimonialsComponent } from './approve-testimonials/approve-testimonials.component';


@NgModule({
  declarations: [IndexComponent,TestimonialsComponent,ApproveTestimonialsComponent],
  imports: [
    CommonModule,
    SharedModule,
    PagesRoutingModule,
    ScrollToModule.forRoot(),
    NgParticlesModule,
    CarouselModule,
    HttpClientModule
  ]
})
export class PagesModule { }
