import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class HomeService {

  data: any = {};
  private readonly url = environment.services; 
  constructor(private http: HttpClient,
              private cookie: CookieService) { }
  
  getCompany$(data: any): Observable<any> {    

    return this.http.post(`${this.url}/Company/SelectCompany`, data).pipe(
      tap((json: any) => {
        this.cookie.set('company',JSON.stringify(json),1,'/')
      }),
      catchError((error) => {
        return new Observable((observer) => {
          observer.error('Error en la solicitud POST');
        });
      })
    );
  } 
}
