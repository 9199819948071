<!-- CONTACT FORM START-->
<section class="section " id="contact">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-xl-6 col-10">
                <div class="text-center">
                    <i class="ti-pencil-alt title-icon text-muted"></i>
                    <h3 class="title"><span class="fw-bold">Formulario de Solicitud de testimonio</span></h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="mt-4 pt-4">
                    <p class="text-muted mt-4"> <span class="fw-bold ">¡Hola, soy Víctor!</span><br></p>
                    <p class="text-muted mt-4"><span class="d-block mt-2">Departe de todo el equipo ARCOL, quiero agradecer de corazón su confianza, nos encanta poder aportar con nuestros servicios 
                        y seguiremos trabajando por mejorar cada día. </span></p>
                    <p class="text-muted mt-4"><span class="d-block mt-2">Su comentario ayudará a que otras personas también confíen en nosotros, tengamos trabajo y podamos cumplir con nuestra misión en el mundo…
                    </span></p>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="custom-form mt-4 pt-4">
                    <form method="post" (ngSubmit)="sendMsg()" [formGroup]="itemData">
                        <p id="error-msg"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-lg-6">
                                <label for="nameCustomer" class="form-label">Nombre:</label>
                                <div class="form-group mt-2">
                                    <input name="name" id="name" type="text" class="form-control" placeholder="Nombre:*" formControlName="nameCustomer" required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="companyCustomer" class="form-label">Empresa:</label>
                                <div class="form-group mt-2">
                                    <input name="company" id="company" type="text" class="form-control" placeholder="Empresa:*" formControlName="companyCustomer" required>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <label for="serviceType" class="form-label">Servicio:</label>
                                <select cSelect formControlName="serviceType" id="lblService" required class="form-control">
                                    <option [value]="null">Selecciona una opción</option>
                                    <option *ngFor="let option of services " [value]="option.idServiceType">{{
                                        option.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">                               
                                <div class="form-group mt-2">   
                                    <label for="puntuaction" class="form-label">Seleccione una puntuación:&nbsp;&nbsp;&nbsp;</label>                                 
                                    <!--input type="text" class="form-control" id="punctuation" placeholder="Puntuacion" formControlName="punctuation"  required /-->
                                    <span class="star" *ngFor="let star of stars" (click)="setPunctuation(star)" [ngClass]="{ 'filled': star <= selectedStars }">&#9733;</span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="canPublish" class="form-label">Este testimonio puede ser publicado:</label>
                                <select cSelect formControlName="canPublish" id="lblPublish" required class="form-control">
                                    <option [value]="null">Selecciona una opción</option>
                                    <option *ngFor="let option of optionsPublish " [value]="option.value">{{
                                        option.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mt-2">
                                    <textarea name="comments" id="comments" rows="10" class="form-control" 
                                        placeholder="¿Puedes decirnos cómo fue el trato personal y profesional? ¿Cómo han sido los resultados obtenidos? ¿Nos recomiendas?"
                                        formControlName="comment" required>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="flag">
                            <div class="col-lg-12 text-end">
                                <input type="submit" id="submit" name="send" class="submitBnt btn btn-primary" value="Enviar">
                                <div id="simple-msg"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CONTACT FORM END-->