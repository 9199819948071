<!-- START TEAM-->
<section class="section bg-light" id="certification">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-6">
                <i class="ti-stamp title-icon text-muted"></i>
                <h3 class="title">Certificaciones</h3>
                <p class="text-muted mt-3">Nos enorgullecemos de las certificaciones que hemos adquirido,
                     las cuales son un testimonio de nuestro compromiso con
                      la excelencia y la satisfacción del cliente.</p>
            </div>
        </div>

        <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let slide of galery">
                <ng-template class="slide" carouselSlide [id]="slide.idImage">
                    <div class="item-galery">
                        <img class="item-cer" [src]="slide.path" [alt]="slide.idImage" [title]="slide.tittle">                       
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>
<!-- END TEAM -->