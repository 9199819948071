<!-- STRT FOOTER -->
<section class="section footer">
    <!-- <div class="bg-overlay"></div> -->
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <ul class="list-inline social mb-0">
                        <li class="list-inline-item"><a href="{{ facebook }}" class="social-icon text-muted me-1" target="_blank"><i
                                    class="ti-facebook"></i></a></li>
                        <!-- Nota: Se habilitaran cuando esten trabajadas estas redes
                        <li class="list-inline-item"><a href="{{ instagram }}" class="social-icon text-muted me-1" target="_blank"><i
                                    class="ti-instagram"></i></a></li>
                        <li class="list-inline-item"><a href="{{ linkedin }}" class="social-icon text-muted me-1" target="_blank"><i
                                    class="ti-linkedin"></i></a></li>
                        -->
                        <li class="list-inline-item"><a class="social-icon text-muted me-1"><i
                            class="ti-instagram"></i></a></li>
                        <li class="list-inline-item"><a class="social-icon text-muted me-1"><i
                            class="ti-linkedin"></i></a></li>
                    </ul>
                </div>                
                <div class="mt-4 pt-2 text-center">
                    <p class="copy-rights text-muted mb-0">
                       {{year}} © Desarrollado por Michael Melendez M - Joseph Cordero M
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END FOOTER -->