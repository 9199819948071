import { Component, OnInit, Input, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.css'],
})
export class ReadMoreComponent implements OnInit {
  @Input() content: any[];
  @Input() limit: number;
  @Input() completeWords: boolean;
  @Input() filter: any;

  isContentToggled: boolean;
  items: any[];
  nonEditedItems: any[];

  constructor() {}

  ngOnInit():void {
    this.isContentToggled = true;
    this.nonEditedItems = this.content;
    this.toggleContent();
  }

  toggleContent() {
    this.isContentToggled = !this.isContentToggled;
    this.items = this.isContentToggled
      ? this.getContent()
      : this.formatContent();
  }

  getContent() {
    this.items = [];
    for (let i = 0; i < this.nonEditedItems.length; i++) {
        if(this.nonEditedItems[i].serviceType.idServiceType == this.filter) {
            this.items.push(this.nonEditedItems[i]);
        }
    }
    return this.items;
  }

  formatContent() {
    this.items = [];
    let textSize = this.limit;
    for (let i = 0; i < this.nonEditedItems.length; i++) {
        if(this.nonEditedItems[i].serviceType.idServiceType == this.filter) {
            if (this.nonEditedItems[i].description.length < textSize) {
                this.items.push({...this.nonEditedItems[i]});
                textSize -= this.nonEditedItems[i].description.length;
            } else {   
                this.items.push({...this.nonEditedItems[i]});
                let descriptionSubstring: string =  this.items[this.items.length - 1].description.substring(0, textSize) + '...';
                this.items[this.items.length - 1].description = descriptionSubstring;
                break;
            }
        }
    }
    return this.items;
  }
}
