<!-- START SERVICES -->
<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-8">
                <i class="ti-ruler-pencil title-icon text-muted"></i>
                <h3 class="title"><span class="fw-bold">Servicios</span></h3>
                <p class="text-muted mt-3 title-subtitle mx-auto">En ARCOL trabajamos para multiplicar su inversión en forma de:</p>
                <p class="text-muted mt-3 title-subtitle mx-auto">Consultoría - Instalación - Mantenimiento.</p>
            </div>
        </div>
        <div *ngIf="isLoadingServices" style="text-align: center; padding-top: calc(100vh / 2); height: 100vh">
            <i class="spinner-grow spinner-grow-sm"></i>
            <span class="m-1">Cargando servicios...</span>
        </div>
        <div *ngIf="!isLoadingServices" class="row mt-5">
            <div *ngFor="let serviceType of servicesType; trackBy: trackByIndex" class="col-md-4">
                <div class="services-box">
                    <div class="services-icon">
                        <img class="services-icon-custom" [src]="serviceType?.icon?.path" [alt]="serviceType?.icon?.icon" [title]="serviceType?.icon?.icon">
                    </div>
                    <div class="mt-3">
                        <h5 class="services-title fw-bold mb-3">{{ serviceType?.name }}</h5>
                        <app-read-more
                            [content]="services"
                            [filter]="serviceType?.idServiceType"
                            [limit]="100"
                            [completeWords]="true"
                        >
                        </app-read-more>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
</section>
<!-- END SERVICES -->
