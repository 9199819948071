<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar">
    
    <div class="container">       
        <img src="{{ company.icon.path }}" alt="Logo de ARCOL" class="img-fluid">        
    </div>
</nav>

<app-publish-testimonials></app-publish-testimonials>
   

