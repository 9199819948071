import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HelperService } from '../helper/services/helper-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  year = new Date().getFullYear();  
  phone: string = '';
  linkedin: string = '';
  facebook: string = '';
  instagram: string = '';
  email: string = '';
  

  constructor(private cookieService: CookieService,
              private helperService: HelperService) { }

  ngOnInit(): void {

    this.helperService.getInfoContact();

    if(this.cookieService.check('phone'))
    {
      this.phone = this.cookieService.get('phone');
      this.linkedin = this.cookieService.get('linkedin');
      this.instagram = this.cookieService.get('instagram');
      this.facebook = this.cookieService.get('facebook');
      this.email = this.cookieService.get('email');
    }
    
  }


  

}
