import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  data: any = {};
  private readonly url = environment.services; 
  constructor(private http: HttpClient) { }
  
  getReviews$(data: any): Observable<any> {    

    return this.http.post(`${this.url}/Review/SelectReviews`, data).pipe(
      catchError((error) => {
        return new Observable((observer) => {
          observer.error('Error en la solicitud POST');
        });
      })
    );
  } 
}
