import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HelperService } from '../helper/services/helper-service.service';
import { CookieService } from 'ngx-cookie-service';
import { ContactService } from './services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

/**
 * Contact component
 */
export class ContactComponent implements OnInit {

  // form
  itemData!: UntypedFormGroup;
  submitted = false;
  phone: string = '';
  linkedin: string = '';
  facebook: string = '';
  instagram: string = '';
  email: string = '';
  private emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  constructor(public formBuilder: UntypedFormBuilder,
              private helperService: HelperService,
              private cookieService: CookieService,
              private service : ContactService) { }

  ngOnInit(): void {

    this.helperService.getInfoContact();

    if(this.cookieService.check('phone'))
    {
      this.phone = this.cookieService.get('phone');
      this.linkedin = this.cookieService.get('linkedin');
      this.instagram = this.cookieService.get('instagram');
      this.facebook = this.cookieService.get('facebook');
      this.email = this.cookieService.get('email');
    }


    this.itemData = this.formBuilder.group({
      nameRemitter: ['', [Validators.required]],
      email: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],

    })
  }

  ValidateFrom() {
    var name = this.itemData.get("nameRemitter")!.value;
    var email = this.itemData.get("email")!.value;
    var subject = this.itemData.get("subject")!.value;
    var comments = this.itemData.get("message")!.value;
    if (name == "" || name == null) {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, ingrese un nombre*</div>";
      return false;
    }
    if (email == "" || email == null) {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, ingrese un correo electrónico*</div>";
      return false;
    }
    if (this.emailPattern.test(email) == false) {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, ingrese un correo electrónico valido*</div>";
      return false;
    }
    if (subject == "" || subject == null) {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, ingrese un asunto*</div>";
      return false;
    }
    if (comments == "" || comments == null) {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, ingrese un mensaje*</div>";
      return false;
    }
    return true
  }

  sendMsg() {
    if (this.ValidateFrom()) {
      document.getElementById('error-msg')!.innerHTML =""
      const dataForm = this.itemData.value;
      this.service.createMessage$(dataForm).subscribe(
        (response) => {
          document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-success error_message'>Mensaje enviado correctamente, muchas gracias</div>";
          setTimeout(() => {            
            document.getElementById('error-msg')!.innerHTML =""
          },3000);
        },
        (error) => {
          document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>Error al intentar enviar el mensaje</div>";
          setTimeout(() => {            
            document.getElementById('error-msg')!.innerHTML =""
          },3000);
        }
      );
    }
  }
}
