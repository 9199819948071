import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ServicesService} from '../services/services/services.service';
import { TestimonialsServicesService } from '../customer-testimonials/services/testimonials-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../client/services/client.service';


@Component({
  selector: 'app-publish-testimonials',
  templateUrl: './publish-testimonials.component.html',
  styleUrl: './publish-testimonials.component.scss'
})
export class PublishTestimonialsComponent {

  // form
  itemData!: UntypedFormGroup;
  submitted = false;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedStars: number = 0;
  services : any[];
  idReview: string;
  optionsPublish : any[] =[
    {value: 1, name: "Si"},
    {value: 0, name: "No"}
  ];
  reviews : any[];
  flag = true;

  constructor(public formBuilder: UntypedFormBuilder,
              private cookieService: CookieService,
              private servicesType: ServicesService,
              private route: ActivatedRoute,
              private router: Router,
              private serviceTestimonial: TestimonialsServicesService,
              private clientService: ClientService) { }

  ngOnInit(): void {

    this.idReview = this.route.snapshot.paramMap.get('review');

    this.itemData = this.formBuilder.group({
      nameCustomer: ['', [Validators.required]],
      companyCustomer: ['', [Validators.required]],
      serviceType: ['', [Validators.required]],
      comment: ['', [Validators.required]],
      punctuation: ['', [Validators.required]],
      canPublish: ['',[Validators.required]],
      isPublish: [null, [Validators.required]]
    });

    this.servicesType.getServicesType$({}).subscribe((data) => {
      this.services = data;
    });

    this.clientService.getReviews$({idReview: this.idReview}).subscribe((reviewsData) => { 
      this.reviews = reviewsData;
      if(this.reviews){
        this.itemData.patchValue(this.reviews[0]);    
        this.itemData.get('serviceType')?.setValue(this.reviews[0].serviceType.idServiceType);
        this.itemData.get('canPublish')?.setValue(this.reviews[0].canPublish ? "1" : "0");
        this.itemData.get('isPublish')?.setValue(this.reviews[0].isPublish ? "1" : null);    
        this.selectedStars = this.reviews[0].punctuation;
        this.flag = this.reviews[0].canPublish;      
      }
    });  

  }

  ValidateFrom() {
    var name = this.itemData.get("nameCustomer")!.value;
    var company = this.itemData.get("companyCustomer")!.value;
    var service = this.itemData.get("serviceType")!.value;
    var comments = this.itemData.get("comment")!.value;
    var canPublish = this.itemData.get("canPublish")!.value;
    var isPublish = this.itemData.get("isPublish")!.value;
    if (name == "" || name == null) {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, ingrese su nombre*</div>";
      return false;
    }
    if (company == "" || company == null) {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, ingrese el nombre de su empresa*</div>";
      return false;
    }
    if (service == "" || service == null || service === "null") {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, seleccione el servicio recibido*</div>";
      return false;
    }
    if (this.selectedStars === 0 || this.selectedStars == null) {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, seleccione la puntuación*</div>";
      return false;
    }
    if (comments == "" || comments == null) {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, seleccione la puntuación*</div>";
      return false;
    }
    if (canPublish == "" || canPublish == null || canPublish === "null") {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, seleccione si puede ser publicado*</div>";
      return false;
    }
    if (isPublish == "" || isPublish == null || isPublish === "null") {
      document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>*Por favor, seleccione si debe ser publicado*</div>";
      return false;
    }
    return true
  }

  sendMsg() {
    if (this.ValidateFrom()) {
      document.getElementById('error-msg')!.innerHTML =""
      const dataForm = this.itemData.value;
      dataForm.idReview = this.idReview;
      dataForm.serviceType  = { idServiceType: dataForm.serviceType };
      dataForm.UserModify = "Admin";
      dataForm.status  = { idStatus: 1 };
      dataForm.isPublish = dataForm.isPublish === "1" ? true : false;
      dataForm.canPublish = dataForm.canPublish === "1" ? true : false;

      this.serviceTestimonial.update$(dataForm).subscribe(
        (response) => {
          document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-success error_message'>Registro publicado correctamente</div>";
          this.cookieService.delete("reviews");
          setTimeout(() => {            
            this.router.navigate(['/']);
          },3000); 
        },
        (error) => {
          document.getElementById('error-msg')!.innerHTML = "<div class='alert alert-warning error_message'>Error al intentar publicar el registro</div>";
        }
      );


    }
  }

  setPunctuation(stars: number) {
    this.selectedStars = stars;
    this.itemData.patchValue({ punctuation: stars });
  }
}
