import { Component, OnInit } from '@angular/core';
import { ServicesService } from './services/services.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})

/**
 * Services component
 */
export class ServicesComponent implements OnInit {

  private servicesData : any = [];
  private servicesTypeData : any = [];
  services : any = [];
  servicesType : any = [];
  trackByIndex: any;
  isLoadingServices: boolean = true;

  ngOnInit(): void {
    this.getServices();
    this.getServicesType();
  }

  constructor(private sevicesService : ServicesService,
    private cookieService : CookieService) { }

  getServices() {
    this.sevicesService.getServices$({}).subscribe((servicesData) => {
      this.servicesData = servicesData;
      this.services = this.servicesData;
      this.isLoadingServices = false;
    });
  }

  getServicesType(){
    this.sevicesService.getServicesType$({}).subscribe((servicesTypeData) => {
      this.servicesTypeData = servicesTypeData;
      this.servicesType = this.servicesTypeData;
    });
  }  

 
}
