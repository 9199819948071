import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  data: any = {};
  private readonly url = environment.services; 
  constructor(private http: HttpClient) { }
  
  getServices$(data: any): Observable<any> {    
    return this.http.post(`${this.url}/Service/SelectService`, data).pipe(
      catchError((error) => {
        return new Observable((observer) => {
          observer.error('Error en la solicitud POST');
        });
      })
    );
  }

  getServicesType$(data: any): Observable<any> {
    return this.http.post(`${this.url}/ServiceType/SelectServiceType`, data).pipe(
      catchError((error) => {
        return new Observable((observer) => {
          observer.error('Error en la solicitud POST');
        });
      })
    );
  }
   
}
