<!-- START TEAM-->
<section class="section bg-light" id="galery">
    
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-8">
                <i class="ti-image title-icon text-muted"></i>
                <h3 class="title">Galería</h3>
                <!--p class="text-muted mt-3">¡Explora nuestra galería de proyectos y descubre 
                    el trabajo excepcional que hemos realizado! En Arcol, estamos orgullosos 
                    de presentar una selección de nuestros proyectos más destacados, 
                    que abarcan una amplia variedad de industrias y desafíos. </p-->
            </div>
        </div>        
        <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let slide of galery">
                <ng-template class="slide" carouselSlide [id]="slide.idImage">
                    <div class="item-sec-galery">
                        <img class="item-img-galery" [src]="slide.path" [alt]="slide.idImage" [title]="slide.title">
                        <!--<div class="overlay-galery">
                            <h3 class="title-galery">{{ slide.tittle }}</h3>
                            <p class="description-galery">{{ slide.description }}</p>
                        </div>-->
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>
<!-- END TEAM -->