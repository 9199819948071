import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import * as pako from 'pako';
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  data: any = {};
  private readonly url = environment.services; 
  constructor(private http: HttpClient,
              private cookie: CookieService) { }
  
  getContact$(data: any): Observable<any> {    

    return this.http.post(`${this.url}/Contact/SelectContact`, data).pipe(
      tap((json: any) => {
        //this.cookie.set('contact',pako.deflate(JSON.stringify(json)),1,'/')
        this.cookie.set('contact',JSON.stringify(json),1,'/')
      }),
      catchError((error) => {
        return new Observable((observer) => {
          observer.error('Error en la solicitud POST');
        });
      })
    );
  } 
  
  createMessage$(data: any): Observable<any> {   
    return this.http.post(`${this.url}/Message/CreateMessage`, data).pipe(
      catchError((error) => {
        return new Observable((observer) => {
          observer.error('Error en la solicitud POST');
        });
      })
    );
  }  
}
