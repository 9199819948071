<!-- STRAT NAVBAR -->

<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar"
    (window:scroll)="windowScroll()">
    <div class="container">
        <img *ngIf="company?.icon?.path" [src]="company?.icon?.path" alt="Logo de ARCOL" class="img-fluid">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
            <span class="ti-menu"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav mx-auto" id="navbar-navlist">
                <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
                    <a [ngxScrollTo]="'#home'" class="nav-link" href="javascript: void(0);">Inicio</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
                    <a [ngxScrollTo]="'#services'" class="nav-link" href="javascript: void(0);">Servicios</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'galery'}">
                    <a [ngxScrollTo]="'#galery'" class="nav-link" href="javascript: void(0);">Galería</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'testi'}">
                    <a [ngxScrollTo]="'#testi'" class="nav-link" href="javascript: void(0);">Testimonios</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'certification'}">
                    <a [ngxScrollTo]="'#certification'" class="nav-link" href="javascript: void(0);">Certificaciones</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
                    <a [ngxScrollTo]="'#contact'" class="nav-link" href="javascript: void(0);">Contáctenos</a>
                </li>
            </ul>
            <div>
                <ul class="text-right list-unstyled list-inline mb-0 nav-social">
                    <li class="list-inline-item text-white nav-number"><span>{{ phone }}</span></li>
                    <!-- <li class="list-inline-item"><a href="{{ linkedin }}"><i class="ti-linkedin"></i></a></li> -->
                    <li class="list-inline-item"><a href="{{ facebook }}" target="_blank"><i
                                class="ti-facebook"></i></a></li>
                    <li class="list-inline-item text-white nav-number"><a href="https://wa.me/50683372092"
                            target="_blank"><i class="ti-mobile"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- END NAVBAR -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
    <!-- START HOME -->
    <section class="back-slide" id="home">
        <img name="slide" class="slide-img" id="isSlideImage" src="assets/images/slider/slide-1.jpg">
        <div class="bg-overlay"></div>
        <div class="home-center">
            <div class="home-desc-center">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="text-center">
                                <h1 class="text-white home-title mb-0">{{company?.slogan}}</h1>
                                <p class="text-white home-subtitle-center home-subtitle mt-2 mb-0 mx-auto">
                                    {{company?.review}}</p>
                                <div class="text-center mt-4">
                                    <a [ngxScrollTo]="'#contact'" class="btn btn-primary">Contáctenos</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- END HOME -->
    <app-services></app-services>
    <app-galery></app-galery>
    <app-client></app-client>
    <app-certification></app-certification>
    <app-contact></app-contact>
    <app-footer></app-footer>
</div>