import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ContactService } from '../../contact/services/contact.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private phoneInfo: any = [];
  private linkedinInfo: any = [];
  private facebookInfo: any = [];
  private instagramInfo: any = [];
  private emailInfo: any = [];
  private contact: any = [];

  constructor(private cookieService: CookieService,
    private contactService: ContactService) { }


    getInfoContact()
    {
      if(!this.cookieService.check('phone')){
        this.contactService.getContact$({}).subscribe((dataContact) => {
          this.contact = dataContact;
          this.getSocialMedia(this.contact);
        });
      }
    }
  
    getSocialMedia(contact: any)
    {
      this.phoneInfo =  this.contact.filter((contacto: { idContact: number; }) => contacto.idContact === 6);
      this.linkedinInfo = this.contact.filter((contacto: { idContact: number; }) => contacto.idContact === 3);
      this.facebookInfo = this.contact.filter((contacto: { idContact: number; }) => contacto.idContact === 2);
      this.instagramInfo = this.contact.filter((contacto: { idContact: number; }) => contacto.idContact === 1);
      this.emailInfo = this.contact.filter((contacto: { idContact: number; }) => contacto.idContact === 5);
        
      this.cookieService.set('phone',this.phoneInfo[0].nameContact,1,'/');
      this.cookieService.set('linkedin',this.linkedinInfo[0].nameContact,1,'/');
      this.cookieService.set('instagram',this.instagramInfo[0].nameContact,1,'/');
      this.cookieService.set('facebook',this.facebookInfo[0].nameContact,1,'/');
      this.cookieService.set('email',this.emailInfo[0].nameContact,1,'/');
    }

}
