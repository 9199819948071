import { Component, OnInit} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CookieService } from 'ngx-cookie-service';
import { GaleryService } from './services/galery.service';


@Component({
  selector: 'app-galery',
  templateUrl: './galery.component.html',
  styleUrl: './galery.component.scss'
})
export class GaleryComponent implements OnInit {

  private dateGalery: any = [];
  galery: any = [];
  
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 500,
    navText: ['', ''],
    autoHeight: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }
  ngOnInit() 
  {
    this.getImages(); 
  }  

  constructor(private galeryService : GaleryService,
    private cookieService: CookieService) 
  {
    
  }
  getImages()
  {
    if(!this.cookieService.check('galery')){
      this.galeryService.getGalery$({}).subscribe((dataGalery) => {
        this.dateGalery = dataGalery.filter((item: { section: { idSection: number}; }) => 
                                         item.section.idSection === 4);
        this.galery = this.dateGalery;
        this.cookieService.set('galery',JSON.stringify(this.galery),1,'/');
      });
    }else
    {
      const data = this.cookieService.get('galery');
      if (data) {
        this.dateGalery = JSON.parse(data);
        this.galery = this.dateGalery;
      } else {
        console.log("No se encontró ninguna cookie con el nombre galery.");
      }      
    }    
  }

}